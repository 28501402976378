import React from "react"
import PropTypes from "prop-types"

import Navbar from '../Navbar'
import Footer from '../Footer'
import WhatsAppButton from '../WhatsAppButton'

import GlobalStyles from '../../styles/global'

const HomeLayout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Navbar />
      <WhatsAppButton />
      <main>{children}</main>
      <Footer />
    </>
  )
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HomeLayout
