import React from "react"

import HomeLayout from "../components/HomeLayout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <HomeLayout>
    <SEO title="404: Not found" />
    <h1>Ops... Essa página não existe. :(</h1>
  </HomeLayout>
)

export default NotFoundPage
